.speech-box  {
  position: relative;
  margin-bottom: -90px;
  pointer-events: none;

  @media (max-width: 800px) {
    margin-bottom: -50px;
  }
  
  .speech-bubble {
    width: calc(100% - 30px);
    max-width: 1200px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    top: -90px;

    @media (max-width: 800px) {
      top: -50px;
    }

    &.bottom {
      top: 0px;
    }
  }

  .speech-box-content {
    width: calc(100% - 30px);
    max-width: 1200px;
    padding: 120px 120px;
    // min-height: 600px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 10;

    top: -80px;

    @media (max-width: 800px) {
      top: -40px;
    }

    &.bottom {
      top: -27px;

      @media (max-width: 800px) {
        top: -20px;
      }

      > div {
        align-items: center;
        width: 100%;
      }
    }

    > div {
      position: relative;
      height: 100%;
      display: flex;
      align-items: flex-start;
      flex-flow: column;
      justify-content: center;

      *:nth-last-child(2) {
        margin-bottom: 0;
      }

      .open-quote {
        position: absolute;
        top: -90px;
        left: -130px;
        width: 70px;
        height: 70px;

        @media (max-width: 1200px) {
          left: -90px;
        }

        @media (max-width: 600px) {
          width: 40px;
          height: 40px;
          top: -60px;
          left: -65px;
        }

        @media (max-width: 400px) {
          top: -70px;
          left: -35px;
        }
      }
    
      .close-quote {
        position: absolute;
        bottom: -120px;
        right: -130px;
        width: 70px;
        height: 70px;

        @media (max-width: 1200px) {
          right: -80px;
        }

        @media (max-width: 600px) {
          width: 40px;
          height: 40px;
          bottom: -70px;
          right: -60px;
        }

        @media (max-width: 400px) {
          bottom: -80px;
          right: -30px;
        }
      }
    }

    &.small {
      > div {
        .open-quote {
          top: -70px;

          @media (max-width: 600px) {
            top: -40px;
          }
  
          @media (max-width: 400px) {
            top: -40px;
          }
        }
      }

      &.bottom {
        top: -27px;
  
        > div {
          .open-quote {
            top: -120px;

            @media (max-width: 600px) {
              top: -70px;
            }
    
            @media (max-width: 400px) {
              top: -70px;
            }
          }
        
          .close-quote {
            bottom: -100px;

            @media (max-width: 600px) {
              bottom: -80px;
            }
    
            @media (max-width: 400px) {
              bottom: -80px;
            }
          }
        }
      }
    }

    @media (max-width: 1200px) {
      padding: 120px 80px;
    }

    @media (max-width: 600px) {
      // min-height: 400px;
      padding: 80px 60px;

      h4, li {
        font-size: 12px;
        line-height: 20px;
      }
    }

    @media (max-width: 400px) {
      padding: 80px 30px;
    }
  }

  &.desktop {
    display: block;
  }

  &.tablet {
    display: none;
  }

  &.mobile {
    display: none;
  }

  &.speakup-variant {
    &.desktop {
      margin-bottom: -50px;
      margin-top: 130px;
    }
  
    &.tablet {
      margin-bottom: -50px;
      margin-top: 110px;
    }
  
    &.mobile {
      margin-bottom: -30px;
      margin-top: 60px;
    }
  }

  @media (max-width: 820px) {
    &.desktop {
      display: none;
    }
  
    &.tablet {
      display: block;
    }
  
    &.mobile {
      display: none;
    }
  }

  @media (max-width: 590px) {
    &.desktop {
      display: none;
    }
  
    &.tablet {
      display: none;
    }
  
    &.mobile {
      display: block;
    }
  }
}

.cls-1 {
  fill: #0d7680;
  opacity: 0.2;
}

.cls-1, .cls-2 {
  fill-rule: evenodd;
}

.cls-2 {
  fill: #fff;
  stroke: #414b4d;
  stroke-width: 7px;
}