html {
  box-sizing: border-box;
  font-size: 16px;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
  padding: 0px;
  margin: 0px;
}