.accordions {
  .accordion {
    &-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 15px 30px;
      border-top: 3px solid $yellow_3;
      border-bottom: 3px solid $yellow_3;

      p {
        font-family: 'Work Sans', sans-serif;
        font-weight: 600;
        margin: 0;
      }

      svg {
        stroke: $black_1;
      }
    }

    &-body {
      padding: 15px 30px;
      background-color: $yellow_2;
      border-bottom: 3px solid $yellow_3;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 5px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0,0,0,0.05), rgba(0,0,0,0));
      }
    }

    &:last-child {
      .accordion-heading {
        border-top: 0;
      }
      .accordion-body {
        border-bottom: 0;
      }
    }

    &:only-child {
      .accordion-heading {
        border-bottom: 0;
      }
    }
  }

  &.content-accordions {
    margin-bottom: 1.875rem;

    &:last-child {
      margin-bottom: 0;
    }

    .accordion-heading {
      background-color: $yellow_1;
      border-radius: 15px;
      
      p {
        font-family: 'ccmeanwhile-bold', 'Work Sans', sans-serif;
        color: $black_1;
      }

      &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      svg {
        stroke: $black_1;
      }
    }

    .accordion-body {
      background-color: $yellow_2;
      border-radius: 15px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}