body {
  background-image: url("../assets/images/Wallpaper-BG.png");
  background-repeat: repeat;
  overflow-x: hidden;
}

img {
  width: 100%;
  height: auto;
}

.hidden {
  display: none;
}