.top-buffer {
  margin-top: 6rem;

  @media (max-width: 820px) {
    margin-top: 3rem;
  }

  h2 {
    margin-top: 6rem;
  }
}

.content-box-with-image {
  padding-top: 6rem;

  @media (max-width: 820px) {
    padding-top: 3rem;
  }

  h2:not(.above-image) {
    color: $white_1;
    text-shadow: 0px 0px 4px $black_1;
  }
}

.content {
  &.box {
    background-color: $white_1;
    border: 1px solid $white_3;
    border-top: 6px solid $black_3;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: $padding $padding * 2;

    @media (max-width: 820px) {
      padding: $padding / 2 $padding / 2;
    }
  }

  &.no-heading,
  &.top-heading {
    margin-top: -30px;
  }
}