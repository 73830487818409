@mixin flex-align-center {
  display: flex;
  align-items: center;
  flex-flow: row;
}

@mixin image-shadow {
  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0.05), rgba(0,0,0,0));
  }

  &:after {
    top: auto;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.05), rgba(0,0,0,0));
  }
}