$blue_1: #14bdcc;
$blue_2: #15a9b6;
$blue_3: #0f8e99;

$yellow_1: #f4dc3b;
$yellow_2: #e6c939;
$yellow_3: #bfa41d;

$green_1: #a3cc29;
$green_2: #94b322;
$green_3: #7e991f;

$orange_1: #ffa526;
$orange_2: #f29018;
$orange_3: #d9720b;

$pink_1: #c09add;
$pink_2: #a37fc0;
$pink_3: #8664a3;

$purple_1: #7987f2;
$purple_2: #5f6ddc;
$purple_3: #4c56bf;

$red_1: #ff4a5e;
$red_2: #e64558;
$red_3: #cc3d4e;

$black_1: #414b4c;
$black_2: #363e40;
$black_3: #2f3638;

$white_1: #ffffff;
$white_2: #f7f7f7;
$white_3: #aaaaaa;

$shadow: rgba(13, 118, 128, 0.2);