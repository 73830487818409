.belt-wrap {
  background-color: $black_3;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 6rem;

  @media (max-width: 820px) {
    margin-top: 3rem;
  }
}

.belt {
  max-width: $max_width;
  width: calc(100% - 30px);
  margin: 0 auto;
  z-index: 2;
  position: relative;
  
  &-heading {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;

    &::after {
      content: "";
      position: absolute;
      left: -100vw;
      top: 0;
      height: 100%;
      width: 200vw;
      background-color: $black_2;
      z-index: 3;
    }

    &.single {
      display: block;
    }
    
    h2 {
      margin-bottom: 0;
      text-align: center;
      color: $white_1;
      position: relative;
      z-index: 4;
    }

    .icon {
      position: relative;
      z-index: 5;
      cursor: pointer;
    }

    .disabled {
      .icon {
        stroke: $white_3;
        cursor: not-allowed;
      }
    }
  }

  &-slide {
    background-color: $yellow_1;
    border-bottom: 16px solid $yellow_3;
    border-radius: 45px;

    &-content {
      margin: 0px 100px;
      position: relative;
      top: -20px;
      margin-bottom: -20px;
      background-color: $yellow_1;
      border: 3px solid $yellow_3;
      border-radius: 30px;
      border-bottom-width: 16px;
      overflow: hidden;

      &.no-video {
        margin-bottom: 20px;
      }

      @media (max-width: 820px) {
        margin: 0px 30px;
      }

      &-image {
        margin: 30px;
        margin-bottom: 15px;
        color: $white_1;

        h3, img {
          background-color: $black_3;
          border: 2px solid $black_3;
        }

        h3 {
          margin: 0;
          padding: 15px;
          text-align: center;
        }
      }
    }

    &-video {
      max-width: $max_width;
      margin: 0px 100px;
      padding: 20px 0px;

      @media (max-width: 820px) {
        margin: 0px 30px;
      }

      .video-box {
        margin-top: 0;
        margin-bottom: 10px;
      }

      &.full-width {
        max-width: 100%;
        padding: 20px 15px;
        padding-top: 30px;

        .video-box {
          border-radius: 15px;
          overflow: hidden;
        }
      }

      p {
        text-align: center;
        font-family: 'Work Sans', sans-serif;
        font-weight: 600;
      }
    }
  }

  &-crumbs {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;

    .crumb {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: $yellow_3;
      margin: 5px;
      cursor: pointer;

      &.active {
        background-color: $white_1;
      }
    }
  }
}