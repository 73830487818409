.video-box {
  width: calc(100% - 30px);
  margin: 0 auto;
  
  &.no-heading {
    margin-top: 6rem;

    @media (max-width: 820px) {
      margin-top: 3rem;
    }
  }

  h2 {
    margin-top: 6rem;

    @media (max-width: 820px) {
      margin-top: 3rem;
    }
  }

  .content.box {
    text-align: center;
    width: 100%;
  }

  .video-box {
    width: 100%;
  }
  
  .video-container {
    max-width: $max_width;
    margin: 0 auto;
    width: 100%;

    .iframe-wrap {
      overflow: hidden;
      padding-top: 56.25%; /* 16:9*/
      position: relative;

      .video {
        width: 100%;
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}