.content {
  max-width: $max_width;
  padding: 0 $padding;
  margin: 0 auto;
  width: calc(100% - 30px);
  margin-bottom: 1rem;

  @media (max-width: 820px) {
    padding: 0 $padding / 2;
  }
}

.above-box {
  margin-bottom: 0.75rem;
  margin-top: -100px;

  @media (max-width: 820px) {
    margin-top: -65px;
    margin-bottom: -0.65rem;
    font-size: 1.25rem;
  }
}

.above-image {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

@import './begin.scss';