#menu {
  width: 100%;
  background-color: $white_1;
  position: sticky;
  display: flex;
  flex-flow: row;
  align-items: center;
  height: $menu_height;
  top: 0;
  z-index: 99;

  .content {
    display: flex;
    align-items: center;
    padding: 0;
    position: relative;
    margin-bottom: 0.4rem;

    @media (max-width: 820px) {
      padding: 0.5rem 0px;
    }

    .hamburger {
      margin-right: 3rem;
      position: relative;
      height: 26px;
      display: block;
      cursor: pointer;
      transition: all 0.3s ease;
      transform-origin: center;
      width: 35px;
      
      @media (max-width: 820px) {
        margin-right: 15px;
      }

      span {
        height: 6px;
        width: 35px;
        background-color: $white_3;
        position: absolute;
        right: 0px;
        top: 0px;
        transition: all 0.4s ease;
        transform-origin: center;
        border-radius: 4px;
      }
    
      &:not(.open) {
        span:nth-child(1) {
          margin-top: 0px;
        }
        span:nth-child(2) {
          margin-top: 10px;
        }
        span:nth-child(3) {
          margin-top: 20px;
        }
      }
    
      &.open {
        transform: rotate(90deg);
        
        span {
          top: 9px;
          border-radius: 40px;
        }
        span:nth-child(1) {
          transform: rotate(45deg);
        }
        span:nth-child(2) {
          opacity: 0;
        }
        span:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  
    h4 {
      margin-bottom: 0;
      color: $blue_1;

      span {
        font-family: 'Work Sans', sans-serif;
      }
    }

    img {
      align-self: flex-end;
      width: 90px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width: 820px) {
        right: 0px;
      }
    }
  }
  
  #progress {
    width: 100%;
    height: 6px;
    display: flex;
    flex-flow: row;
    position: absolute;
    bottom: 0;

    &-complete {
      flex: 0%;
      background-color: $green_1;
    }

    &-bg {
      flex: 100%;
      background-color: $white_3;
    }
  }
}

#menu-content {
  position: fixed;
  top: -200px;
  width: 100%;
  z-index: 90;
  transition: top 0.4s ease;

  &.open {
    top: 64px;
  }
  
  > div {
    max-width: 80rem;
    margin: 0 auto;
    width: calc(100%);

    > div {
      max-width: 400px;

      > div {
        background-color: #19abb8;
        cursor: pointer;
  
        &:nth-child(odd) {
          background-color: #1ba6b3;
        }
  
        h4 {
          color: $white_1;
          margin-bottom: 0;
          padding: 4px 32px;
        }
  
        &:hover {
          background-color: $white_1;
          h4 {
            color: $green_1;
          }
        }
      }
    }
    
  }
}