.icon {
  &.arrow-down, &.arrow-up, &.arrow-left, &.arrow-right {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 7px;
    width: 30px;
    height: 30px;
    stroke: $white_1;
  }

  &.icon-correct {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/images/Icon-Correct.png);

    background-color: $white_3;
    border-radius: 0.5rem;
  }

  &.icon-incorrect {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/images/Icon-Incorrect.png);

    background-color: $white_3;
    border-radius: 0.5rem;
  }

  &.icon-alarm {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/images/Icon-Alarm.png);

    background-color: $white_3;
    border-radius: 0.5rem;
  }

  &.icon-calendar {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/images/Icon-Calendar.png);

    background-color: $white_3;
    border-radius: 0.5rem;
  }

  &.icon-close {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/images/Icon-Close.png);

    background-color: $white_3;
    border-radius: 0.5rem;
  }

  &.quote-close,
  &.quote-open {
    fill: $black_3;
    stroke: $black_3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
    fill-rule: evenodd;

    width: 30px;
    height: 30px;
  }

  &.pointer-down,
  &.pointer-up {
    fill: $black_3;
    stroke: $black_3;
  }

  &.icon-speech {
    width: 35px;
    height: 35px;
    path:first-child {
      fill: none;
      stroke: $black_3;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 4px;
    }

    path:last-child {
      fill: $black_3;
    }
  }
}
