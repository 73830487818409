button {
  min-width: 25.125rem;
  min-height: 3rem;
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: 3.125rem;
  text-align: center;
  outline: none;
  font-family: 'ccmeanwhile-bold', 'Work Sans', sans-serif;
  border-width: 2px;
  border-style: solid;
  border-radius: 2rem;
  text-transform: uppercase;
  cursor: pointer;

  a, a:visited, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 30rem) {
    min-width: 100%;
  }

  &.trigger {
    margin: 0 auto;
    margin-bottom: 20px;
    display: block;
  }

  &.blue {
    color: $white_1;
    background-color: $blue_2;
    border-color: $blue_2;

    &:hover {
      background-color: $blue_1;
      border-color: $blue_1;
    }
  
    &:active {
      background-color: $white_1;
      border-color: $blue_1;
      color: $blue_1;
    }
  }

  &.green {
    color: $white_1;
    background-color: $green_2;
    border-color: $green_2;

    &:hover {
      background-color: $green_1;
      border-color: $green_1;
    }
  
    &:active {
      background-color: $white_1;
      border-color: $green_1;
      color: $green_1;
    }
  }

  &:disabled {
    background-color: $white_1;
    border-color: $white_3;
    color: $white_3;
    cursor: not-allowed;
  }

  a {
    width: 100%;
    display: block;
  }
}

.tfButton {
  all:unset;
  font-family:Helvetica,Arial,sans-serif;
  display:inline-block;
  max-width:100%;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  background-color:#12A9B6;
  color:#FFFFFF;
  font-size:20px;
  border-radius:25px;
  padding:0 33px;
  font-weight:bold;
  height:50px;
  cursor:pointer;
  line-height:50px;
  text-align:center;
  margin:0;
  text-decoration:none;
}