#begin {
  height: calc(100vh - #{$menu_height});
  position: relative;

  .hero {
    height: calc(100vh - #{$menu_height} - #{$bar_height});
  }

  @media (max-width: 750px) {
    height: 100%;

    .hero {
      height: 100%;
    }
  }

  .bar {
    @include flex-align-center;
    position: absolute;
    height: $bar_height;
    bottom: 0;
    left: 0;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    border-top: 6px solid $black_3;
    width: 100%;

    @media (max-width: 750px) {
      position: static;
    }

    .content {
      width: auto;
      margin: 0 auto;
      display: flex;
      flex-flow: column;

      @media (max-width: 820px) {
        width: 100%;
      }
      
      svg {
        stroke: $black_3;
      }

      button {
        margin-bottom: 0;
        max-width: 25.125rem;
        margin: 0 auto;
      }

      .icon {
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
  }
}