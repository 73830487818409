@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600;700&display=swap');

@font-face {
  font-family: 'ccmeanwhile-bold';
  src: url('../assets/fonts/meanwhile_bold_macroman/ccmeanwhile-bold-webfont.woff2') format('woff2'),
       url('../assets/fonts/meanwhile_bold_macroman/ccmeanwhile-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ccmeanwhile-bolditalic';
  src: url('../assets/fonts/meanwhile_bolditalic_macroman/ccmeanwhile-bolditalic-webfont.woff2') format('woff2'),
       url('../assets/fonts/meanwhile_bolditalic_macroman/ccmeanwhile-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ccmeanwhile-italic';
  src: url('../assets/fonts/meanwhile_italic_macroman/ccmeanwhile-italic-webfont.woff2') format('woff2'),
       url('../assets/fonts/meanwhile_italic_macroman/ccmeanwhile-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ccmeanwhile-regular';
  src: url('../assets/fonts/meanwhile_regular_macroman/ccmeanwhile-regular-webfont.woff2') format('woff2'),
       url('../assets/fonts/meanwhile_regular_macroman/ccmeanwhile-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ccmeanwhileuncial';
  src: url('../assets/fonts/meanwhile_uncial_macroman/ccmeanwhileuncial-webfont.woff2') format('woff2'),
       url('../assets/fonts/meanwhile_uncial_macroman/ccmeanwhileuncial-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
