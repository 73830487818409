h1 {
  font-family: 'ccmeanwhileuncial', 'Work Sans', sans-serif;
  line-height: 5.625rem;
  font-size: 3.75rem;
  margin-bottom: 3.125rem;
}

h2 {
  font-family: 'ccmeanwhile-bold', 'Work Sans', sans-serif;
  font-weight: bold;
  line-height: 3.75rem;
  font-size: 2.5rem;
  margin-bottom: 1.875rem;

  @media (max-width: 820px) {
    font-size: 1.5rem;
  }
}

h3 {
  font-family: 'ccmeanwhile-bold', 'Work Sans', sans-serif;
  font-weight: bold;
  line-height: 2.5rem;
  font-size: 2rem;
  margin-bottom: 3.125rem;

  @media (max-width: 820px) {
    font-size: 1rem;
  }
}

h4 {
  font-family: 'ccmeanwhile-bold', 'Work Sans', sans-serif;
  font-weight: bold;
  line-height: 2.25rem;
  font-size: 1.5rem;
  margin-bottom: 3.125rem;

  @media (max-width: 820px) {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-bottom: 1.125rem;
  }
}

p {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  line-height: 1.6875rem;
  font-size: 1.125rem;
  margin-bottom: 1.875rem;

  &.quote {
    font-weight: 300;
    line-height: 2.25rem;
    font-size: 1.5rem;
    margin-bottom: 3.125rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  line-height: 2.25rem;
  font-size: 1.125rem;
  margin-bottom: 1.875rem;
  padding-left: 3.125rem;

  @media (max-width: 820px) {
    padding-left: 1.25rem;
  }

  li {
    list-style-type: disc;
  }
}

a {
  margin-bottom: 1.875rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.text-center {
  text-align: center;
}

.no-mb {
  margin-bottom: 0;
}