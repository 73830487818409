.time-branch {
  width: calc(100% - 120px);
  margin: 0 auto;
  background-color: $blue_2;
  padding-top: 100px;
  margin-top: -185px;
  padding-bottom: 2rem;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  flex-flow: column;

  @media (max-width: 820px) {
    width: calc(100%);
    margin-top: -125px;
  }

  > div {
    margin: 0.4rem 2rem;
    padding: 1rem 1rem;
    border: 2px solid $white_1;
    width: calc(100% - 4rem);
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    background-color: $blue_1;
    cursor: pointer;

    &:hover {
      background-color: $red_1;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > img:first-child {
      width: auto;
      height: 50px;
    }

    h4 {
      margin: 0;
      color: $white_1;
    }

    > img:last-child {
      width: auto;
      height: 50px;
    }
  }
}