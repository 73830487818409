.quiz {
  position: relative;
  margin-top: 6rem;

  @media (max-width: 820px) {
    margin-top: 3rem;
  }

  .image-wrap {
    &:before, &:after {
      content: none;
    }
  }

  .contained-quiz-img {
    padding: 0;
    margin: 0 auto;
    display: block;
  }

  h2 {
    color: $white_1;
    text-shadow: 0px 0px 4px #000;
  }

  .content {
    &.box {
      border-radius: 0;
      margin-bottom: 0;
      position: relative;

      .fb-icon {
        position: absolute;
        top: 3.125rem;
        left: -45px;
        width: 30px;
        height: 30px;
      }
    }
  }

  .quiz-results {
    button {
      display: block;
      margin: 0 auto;
      margin-bottom: 0;
    }
  }

  .quiz-content {
    background-color: $blue_2;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: $padding / 2 $padding;

    @media (max-width: 820px) {
      padding: $padding / 2 $padding / 2;
    }

    button {
      margin: 0 auto;
      display: block;
      background-color: $blue_1;
      color: $white_1;
      border-color: $white_1;

      &:disabled {
        opacity: 0.4;
      }
    }

    &.correct {
      background-color: $green_2;

      button {
        background-color: $green_1;
      }
    }

    &.incorrect {
      background-color: $red_2;

      button {
        background-color: $red_1;
      }
    }

    .options {
      margin-bottom: $padding / 2;
      display: flex;

      .option {
        border-radius: 0.5rem;
        background-color: $white_1;
        border: 2px solid $black_3;
        padding: $padding / 4;
        display: flex;
        flex-flow: row;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.8;
        }

        &:hover {
          background-color: $white_2;
        }

        *:not(div) {
          font-family: 'ccmeanwhile-bold', 'Work Sans', sans-serif;
          font-weight: bold;
          line-height: 2.25rem;

          @media (max-width: 820px) {
            font-size: 1rem;
          }
        }

        &:not(:last-child) {
          margin-bottom: $padding / 4;
        }
        
        > div:first-child {
          width: 20px;
          height: 20px;
          background-color: $white_3;
          margin-right: 10px;
          position: relative;
          top: 8px;
          border-radius: 4px;

          &:after {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-color: $white_1;
          }
        }

        &.active {
          > div:first-child {
            &:after {
              background-color: $black_3;
            }
          }
        }

        > div:last-child {
          flex: 1;
          -webkit-user-select: none; /* Safari */
          -ms-user-select: none; /* IE 10+ and Edge */
          user-select: none; /* Standard syntax */
        }

        &.stacked {
          display: flex;
          flex-flow: column;
          align-items: center;

          > div {
            margin: 0;
            margin-bottom: 15px;
          }

          img {
            max-width: 150px;
            width: 100%;
            height: auto;
            min-height: 100px;
          }
        }
      }

      &.row {
        flex-flow: row;
        justify-content: space-between;
  
        .option {
          margin-bottom: 0px;
          text-align: center;
        }

        .option:first-child:nth-last-child(2),
        .option:first-child:nth-last-child(2) ~ .option {
          width: calc(50% - 4px);
        }

        /* three items */
        .option:first-child:nth-last-child(3),
        .option:first-child:nth-last-child(3) ~ .option {
          width: calc(33.33% - 4px);
        }
      }
  
      &.column {
        flex-flow: column;
      }
    }
  }
}